<template>
    <b-card title="Workshop Information">
        <!-- form -->
        <b-form>
            <b-row>
                <!-- Workshop Name -->
                <b-col md="6">
                    <b-form-group label="Workshop Name">
                        <b-form-input v-model="workshopName" placeholder="Please Enter Workshop Name" />
                    </b-form-group>
                </b-col>

                <!-- Business Name -->
                <b-col md="6">
                    <b-form-group label="Business Name">
                        <b-form-input v-model="businessName" placeholder="Please Enter Business Name" />
                    </b-form-group>
                </b-col>

                <!-- Store Address -->
                <b-col md="6">
                    <b-form-group label="Workshop Address">
                        <b-form-input v-model="address" placeholder="Please Enter Workshop Address" />
                    </b-form-group>
                </b-col>

                <!-- Store Location -->
                <b-col md="6">
                    <b-form-group label="Workshop Location">
                        <b-form-input v-model="location" placeholder="Please Enter Workshop Location" />
                    </b-form-group>
                </b-col>

                <!-- mobile -->
                <b-col md="6">
                    <b-form-group label="Mobile No">
                        <b-form-input v-model="mobileNo" placeholder="Please Enter Mobile no" />
                    </b-form-group>
                </b-col>

                <!-- phone -->
                <b-col md="6">
                    <b-form-group label="Phone No">
                        <b-form-input v-model="phoneNo" placeholder="Please Enter Phone No" />
                    </b-form-group>
                </b-col>

                <!-- checkBox -->
                <b-col md="6" class="mt-3">
                    <b-form-checkbox checked="false" v-model="enabelOperationTime">Enable Operation Time
                    </b-form-checkbox>
                </b-col>

            </b-row>
            <hr>
            <b-row v-if="enabelOperationTime">


                <!-- Opening Time -->
                <b-col md="3" class="mt-1">
                    <b-form-group label="Opening Time">
                        <b-time v-model="openingTime"></b-time>
                    </b-form-group>
                </b-col>

                <!-- Closing Time -->
                <b-col md="3" class="mt-1">
                    <b-form-group label="Closing Time">
                        <b-time v-model="closingTime"></b-time>
                    </b-form-group>
                </b-col>

                <!-- Week Off Days -->
                <b-col md="6" class="mt-1">
                    <b-form-group label="Weekly Off Day">
                        <v-select v-model="weekOffDay" label="name" placeholder="Select Week Off Day"
                            :options="weekDaysOption"></v-select>
                    </b-form-group>
                    <b-form-group label="Select Default Time Zone">
                        <v-select v-model="defaultTimeZone" :options="timeZoneOption" />
                        <!-- {{ new Date().toLocaleString() }} -->
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" class="text-center">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-1 mr-1"
                        @click="addUpdateWorkshopInformation">
                        {{ addUpdateButton }}
                    </b-button>
                    <!-- <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" class="mt-1"
                        variant="outline-secondary" @click="resetForm">
                        Reset
                    </b-button> -->
                </b-col>
            </b-row>
            <hr>
            <b-row>
                <b-col md="12">
                    <!-- table -->
                    <vue-good-table :line-numbers=true :columns="columns" :rows="rows" :rtl="direction" :search-options="{
    enabled: true,
    externalQuery: searchTerm
}" :select-options="{
    enabled: false,
    selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
    selectionInfoClass: 'custom-class',
    selectionText: 'rows selected',
    clearSelectionText: 'clear',
    disableSelectInfo: true, // disable the select info panel on top
    selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
}" :pagination-options="{
    enabled: true,
    perPage: pageLength
}">
                        <template slot="table-row" slot-scope="props">

                            <!-- Column: Currency -->

                            <!-- Column: Weekend Day -->
                            <span v-if="props.column.field === 'weeklyOffDay'">
                                <span v-if="props.row.weeklyOffDay == 0">
                                </span>
                                <span v-else-if="props.row.weeklyOffDay == 1">
                                    Sunday
                                </span>
                                <span v-else-if="props.row.weeklyOffDay == 2">
                                    Monday
                                </span>
                                <span v-else-if="props.row.weeklyOffDay == 3">
                                    Tuesday
                                </span>
                                <span v-else-if="props.row.weeklyOffDay == 4">
                                    Wednesday
                                </span>
                                <span v-else-if="props.row.weeklyOffDay == 5">
                                    Thursday
                                </span>
                                <span v-else-if="props.row.weeklyOffDay == 6">
                                    Friday
                                </span>
                                <span v-else-if="props.row.weeklyOffDay == 7">
                                    Saturday
                                </span>
                            </span>

                            <!-- Column: Action -->
                            <span v-else-if="props.column.field === 'action'">
                                <span>
                                    <b-button
                                        @click="editButton(props.row.id, props.row.name, props.row.businessName, props.row.address, props.row.location, props.row.mobileNo, props.row.phoneNo, props.row.enabelOperationTime, props.row.openingTime, props.row.closingTime, props.row.weekOffDay, props.row.defaultTimeZone)"
                                        variant="flat-primary"><feather-icon icon="EditIcon"
                                            class="text-body align-middle mr-25" /></b-button>
                                    <b-button @click="deleteWorkshop(props.row.id)" variant="flat-primary"><feather-icon
                                            icon="TrashIcon" class="text-body align-middle mr-25" /></b-button>
                                </span>
                            </span>

                            <!-- Column: Common -->
                            <span v-else>
                                {{ props.formattedRow[props.column.field] }}
                            </span>
                        </template>

                        <!-- pagination -->
                        <template slot="pagination-bottom" slot-scope="props">
                            <div class="d-flex justify-content-between flex-wrap">
                                <div class="d-flex align-items-center mb-0 mt-1">
                                    <span class="text-nowrap ">
                                        Showing 1 to
                                    </span>
                                    <b-form-select v-model="pageLength" :options="['5','10', '20', '50','100']" class="mx-1"
                                        @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                                    <span class="text-nowrap"> of {{ props.total }} entries </span>
                                </div>
                                <div>
                                    <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength"
                                        first-number last-number align="right" prev-class="prev-item"
                                        next-class="next-item" class="mt-1 mb-0"
                                        @input="(value) => props.pageChanged({ currentPage: value })">
                                        <template #prev-text>
                                            <feather-icon icon="ChevronLeftIcon" size="18" />
                                        </template>
                                        <template #next-text>
                                            <feather-icon icon="ChevronRightIcon" size="18" />
                                        </template>
                                    </b-pagination>
                                </div>
                            </div>
                        </template>
                    </vue-good-table>
                </b-col>
            </b-row>
        </b-form>
    </b-card>
</template>

<script>
import {
    BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BFormTextarea, BFormFile, BPagination,
    BMedia, BMediaAside, BMediaBody, BLink, BImg, BAvatar, BFormCheckbox, BTime, BFormSelect
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import api from '@/store/api'
import { VueGoodTable } from 'vue-good-table'
import util from '@/store/utils'
export default {
    components: {
        BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BFormTextarea, BFormFile, BPagination,
        BMedia, BMediaAside, BMediaBody, BLink, BImg, BAvatar, BFormCheckbox, BTime, vSelect, flatPickr, VueGoodTable, BFormSelect
    },
    data() {
        return {
            id: 0,
            addUpdateButton: 'Add',
            workshopName: '',
            businessName: '',
            address: '',
            location: '',
            mobileNo: '',
            phoneNo: '',
            enabelOperationTime: false,
            openingTime: '',
            closingTime: '',
            weekDaysOption: [],
            weekOffDay: {'id': 0, 'name':'---Select---'},
            timeZoneOption: [],
            defaultTimeZone: '',

            pageLength: 5,
            dir: false,
            columns: [

                {
                    label: 'Workshop Name',
                    field: 'name',
                },
                {
                    label: 'Business Name',
                    field: 'businessName',
                },
                {
                    label: 'Address',
                    field: 'address',
                },
                {
                    label: 'Location',
                    field: 'location',
                },
                {
                    label: 'Mobile No',
                    field: 'mobileNo',
                },
                {
                    label: 'Phone No',
                    field: 'phoneNo',
                },
                {
                    label: 'Opening Time',
                    field: 'openingTime',
                },
                {
                    label: 'Closing Time',
                    field: 'closingTime',
                },
                {
                    label: 'Week Off Day',
                    field: 'weeklyOffDay',
                },
                {
                    label: 'Default Time Zone',
                    field: 'defaultTimeZone',
                },
                {
                    label: 'Action',
                    field: 'action',
                },


            ],
            rows: [],
            searchTerm: '',
            options: [],

        }
    },
    methods: {
        getWeekend() {
            let self = this;

            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/getWeekDayList', data))
                .then(function (response) {
                    let i=0;
                    for (let index = 0; index < response.data.length; index++) {
                        i++;
                        self.weekDaysOption.push({'id':i,'name':response.data[index]})
                    }
                })
                .catch(function (error) {
                    console.log(error);

                });
        },
        getTimeZoneList(){
            
            let self = this;

            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/getAllTimezonesList', data))
                .then(function (response) {
                    self.timeZoneOption=response.data;
                })
                .catch(function (error) {
                    console.log(error);

                });
        },
        getWorkshopInformation() {
            let self = this;
            self.id = 0;
            self.addUpdateButton = "Add";
            self.workshopName='';
            self.businessName='';
            self.address='';
            self.location='';
            self.mobileNo='';
            self.phoneNo='';
            self.enabelOperationTime=false;
            self.openingTime='';
            self.closingTime='';
            self.weekOffDay= {'id': 0, 'name':'---Select---'},
            self.defaultTimeZone=''

            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/workshops', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.rows = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        addUpdateWorkshopInformation() {
            let self = this;
            if (!(self.workshopName == '') && !(self.mobileNo == '')) {
                if (self.addUpdateButton == 'Add') {

                    var axios = require('axios');
                    var data = JSON.stringify({
                        "name": self.workshopName,
                        "businessName": self.businessName,
                        "address": self.address,
                        "location": self.location,
                        "mobileNo": self.mobileNo,
                        "phoneNo": self.phoneNo,
                        "enabelOperationTime": self.enabelOperationTime,
                        "openingTime": self.openingTime,
                        "closingTime": self.closingTime,
                        "weeklyOffDay": self.weekOffDay.id,
                        "defaultTimeZone": self.defaultTimeZone
                    });
                    axios(api.getApi('Post', '/masters/workshops', data))
                        .then(function (response) {
                            //console.log(JSON.stringify(response.data));
                            self.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: `Workshop Added Successfully!`,
                                    icon: 'SaveIcon',
                                    variant: 'success',
                                },
                            })
                            self.getWorkshopInformation();
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                } else {
                    let self = this;
                    var axios = require('axios');

                    var data = JSON.stringify({
                        "name": self.workshopName,
                        "businessName": self.businessName,
                        "address": self.address,
                        "location": self.location,
                        "mobileNo": self.mobileNo,
                        "phoneNo": self.phoneNo,
                        "enabelOperationTime": self.enabelOperationTime,
                        "openingTime": self.openingTime,
                        "closingTime": self.closingTime,
                        "weeklyOffDay": self.weekOffDay.id,
                        "defaultTimeZone": self.defaultTimeZone
                    });
                    axios(api.getApi('put', '/masters/workshops/' + self.id, data))
                        .then(function (response) {
                            //console.log(JSON.stringify(response.data));

                            self.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: `Workshop Information Updated Successfully!`,
                                    icon: 'SaveIcon',
                                    variant: 'success',
                                },
                            })
                            self.getWorkshopInformation();

                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
            } else {
                this.$swal({
                    title: '',
                    text: "Please Enter Workshop Name And Mobile No.",
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                })
            }
        },
        deleteWorkshop(id) {
            let self = this;
            this.$swal({
                title: '',
                text: "Are You Sure You Want To Delete!",
                icon: 'error',
                customClass: {
                    confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    var axios = require('axios');
                    var data = '';
                    axios(api.getApi('delete', '/masters/workshops/' + id, data))
                        .then(function (response) {
                            //console.log(JSON.stringify(response.data));
                            self.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: `Workshop Deleted Successfully!`,
                                    icon: 'SaveIcon',
                                    variant: 'success',
                                },
                            })
                            self.getWorkshopInformation();
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
            })
        },
        editButton(id, name, businessName, address, location, mobileNo, phoneNo, enabelOperationTime, openingTime, closingTime, weekOffDayId, timeZone) {
            let self = this;
            self.addUpdateButton = 'Update';
            self.id = id;
            self.workshopName = name;
            self.businessName = businessName;
            self.address = address;
            self.location = location;
            self.mobileNo = mobileNo;
            self.phoneNo = phoneNo;
            self.enabelOperationTime = enabelOperationTime;
            self.openingTime = openingTime;
            self.closingTime = closingTime;
            if (weekOffDayId > 0) {
                const object = self.weekDaysOption.find(obj => obj.id == weekOffDayId);
                self.weekOffDay = { 'id': object.id, 'name': object.name };
            } else {
                self.weekOffDay = util.weekOffDay();
            }
            self.defaultTimeZone = timeZone;

        }
    },
    created() {
        this.getWeekend();
        this.getTimeZoneList();
        this.getWorkshopInformation();
    }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '~vue-good-table/dist/vue-good-table.css';
</style>